import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import companyRoutes from './modules/company-routes'
import informsRoutes from './modules/informs-routes'
import productsRoutes from './modules/products-routes'
import projectsRoutes from './modules/projects-routes'
// import store from '../store'

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'main' },
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'empty'},
    component: () => import('../views/Login.vue')
  },
  {
    path: '/adminpanel',
    name: 'AdminPanel',
    meta: { layout: 'empty'},
    component: () => import('../views/AdminPanel.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    meta: { layout: 'empty' },
    component: () => import('../views/Navigations/Contacts')
  },
  {
    path: '/informs/news',
    name: 'News',
    meta: {layout: 'empty'},
    component: () => import( '../views/Navigations/Informs/News')
  },
  {
    path: '/projects/projectspage',
    name: 'Projectspage',
    meta: { layout: 'empty' },
    component: () => import('../views/Navigations/Projects/Projectspage')
  },
  {
    path: '/products/productspage',
    name: 'Productspage',
    meta: { layout: 'empty' },
    component: () => import('../views/Navigations/Products/Productspage')
  },
];

const routes = baseRoutes.concat(companyRoutes, productsRoutes, projectsRoutes, informsRoutes);

export default new VueRouter({
  routes,
  // base: './',
  mode: 'history',
});
// const router = new VueRouter({
//   routes
// })

// export default router
